.hoverMe{
  cursor: pointer;
  background: linear-gradient(45deg,rgba(115, 215, 245, 0.145),transparent);
  transition: .2s ease-in-out;
}
.hoverMe:hover{
  background-color: rgba(0, 0, 0, 0.809);
  color: white;
}
.hoverMe:hover .card-header  {
  border-bottom: 1px solid rgba(255, 255, 255, 0) !important;
}